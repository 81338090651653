<template>
	<main class="vcc-main">
		<router-link :to="{ name: 'AdminPage' }">← Назад</router-link><br><br>
		<h1 class="heading">Выбери вебинар для редактирования</h1>
		<h2 class="subheading heading__hr">Вебинары в архиве</h2>
		<ul class="past-webinars--list">
			<li v-for="webinar, key in getWebinars" :key="'webinar-past'+key">
				<router-link :to="{ name: 'EditArchiveWebinar', params: { id: webinar.id } }">
					<webinar :webinar_data="webinar" :is_past="true" :no_btns="true"/>
				</router-link>
			</li>
		</ul>
	</main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Webinar from '@/components/cards/Webinar.vue'
export default {
	name: 'PastWebinars',
	components: { Webinar },
	computed: {
		...mapGetters(['getWebinars']),
	},
	methods: {
		...mapActions(['fetchArchiveWebinars'])
	},
	created() {
		this.fetchArchiveWebinars()
	}
}
</script>

<style>
	.past-webinars--list li a {
		text-decoration: none;
	}
	.past-webinars--list li a * {
		cursor: unset;
		pointer-events: none;
	}
</style>
